.sbl {
  height: 20px;
  width: 20px;
  color: #e093a9;
  position: relative;
  display: inline-block;
  border: 5px solid;
  border-radius: 50%;
  top: 4px;
  left: 2px;
  border-top-color: transparent;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
