.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 50%;
  padding: 5px;
}

.popup-arrow {
  color: rgb(255, 255, 255);
}

[role='tooltip'].popup-content {
  width: 266px;
  box-shadow: rgb(222 222 222) 0px 0px 31px;
  padding: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 160%;
  font-weight: 500;
  border-radius: 4px;
  @media (max-width: 768px) {
    width: 200px !important;
  }
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

@media (max-width: 414px) {
  [role='tooltip'].popup-content {
    width: 200px;
  }
}

.popup-content {
  left: 30px !important;
}
