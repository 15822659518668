@import '../../../styles/customMediaQueries.css';
.root {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  display: block !important;
  position: relative;
  @media (--viewportMedium) {
    padding-bottom: 367px;
  }
  @media (--viewportLarge) {
    padding-bottom: 300px;
  }
  &.withoutFooter {
    padding-bottom: 0;
  }
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

.main {
  display: block;
}
.footer {
  width: 100%;
  @media (--viewportMedium) {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  & > footer {
    padding: 0 24px;
  }
}
.transactionFooter {
  padding-bottom: 0;
  @media (--viewportLarge) {
    padding-bottom: 333px;
  }
  & .footer {
    display: none;
    @media (--viewportLarge) {
      display: block;
    }
  }
}
