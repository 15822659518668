@import '../../styles/customMediaQueries.css';

/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  height: 100vh;
  padding: var(--modalPadding);
  padding-top: 50px;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--colorWhite);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
  & > button {
    position: absolute;
  }
}

.heading {
  /* Font */
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.price {
  margin: 0;
  color: var(--colorGrey900);
  font-size: 24px;
  line-height: 32px;
  font-weight: var(--fontWeightBold);
}

.perUnit {
  /* Font */
  composes: h4 from global;
  font-weight: var(--fontWeightBold);

  color: var(--marketplaceColor);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
  }
}

.author {
  composes: p from global;
  margin: 0px 24px 32px 24px;

  display: flex;
  flex-direction: row;
  align-items: center;

  @media (--viewportMedium) {
    width: 100%;
    margin: 12px 0 36px 0;
    color: var(--colorGrey700);
  }
}

.providerAvatar {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    margin-right: 10px;
  }
}

.providerNamePlain {
  display: inline;

  @media (--viewportLarge) {
    display: none;
  }
}

.providerNameLinked {
  display: none;

  @media (--viewportLarge) {
    display: inline;
  }
}

.orderHeading {
  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.orderTitle {
  /* Font */
  color: var(--colorGrey700);

  margin-top: 0;
  margin-bottom: 2px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.orderHelp {
  composes: h5 from global;
  display: none;

  @media (--viewportMedium) {
    color: var(--colorGrey700);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.openOrderForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 18px 24px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportLarge) {
    display: none;
  }
  & > button {
    width: auto;
    min-width: 150px;
  }
}

.priceContainerInCTA,
.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 4px;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 3px 0;

  @media (--viewportMedium) {
    padding: 4px 0;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    margin-right: unset;
    padding: 0;
  }
}
.priceContainer {
  margin-bottom: 14px;
}
.priceContainerInCTA {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: unset;
}
.colorSec {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  & > * {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 120%;
  }
  & > label {
    color: var(--colorGrey600);
    font-weight: var(--fontWeightRegular);
  }
  & > span {
    color: var(--colorGrey900);
    font-weight: var(--fontWeightSemiBold);
    margin-left: 4px;
  }
}
.tag {
  font-size: 14px;
  line-height: 100%;
  color: var(--colorGrey400);
  margin-bottom: 10px;
  display: inline-flex;
  width: 100%;
}
.priceValue {
  composes: h3 from global;
  color: var(--marketplaceColor);
  font-size: 24px;
  line-height: 120%;

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnitInCTA {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
  }
}

.closedListingButton {
  border-left: 1px solid var(--colorGrey100);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.receipt {
  flex-shrink: 0;
}

.error {
  composes: p from global;
  color: var(--colorFail);
  padding: 0;
}
.errorSidebar {
  color: var(--colorFail);
  padding: 0;
  line-height: 120%;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
}

.ratingSec {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 14px;
  & > * {
    position: relative;
    &:not(:last-child) {
      &::after {
        width: 4px;
        height: 4px;
        border-radius: 100px;
        background-color: var(--colorGrey700);
        content: ' ';
        display: inline-flex;
        left: 10px;
        position: relative;
      }
    }
  }
  & .rating {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--colorGrey700);
    & > span {
      margin-right: 5px;
      line-height: 100%;
      margin-top: -2px;
      & > svg {
        width: 14px;
        height: 14px;
        &:not(:last-child) {
          margin: 0 2px 0 0;
        }
      }
    }
  }
  & .totalReviews {
    font-size: 14px;
    color: var(--colorGrey700);
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: var(--marketplaceColor);
    }
  }
}

.shareProductSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 32px;
  flex-wrap: wrap;
  @media (--viewportSmall) {
    flex-wrap: nowrap;
  }
  & > button {
    border-radius: 100px;
    box-shadow: var(--boxShadow);
    padding: 10px 20px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: solid 1px var(--colorGrey100);
    font-size: 14px;
    line-height: 110%;
    width: 100%;
    cursor: pointer;
    transition: var(--transitionStyle);
    &:hover {
      transition: var(--transitionStyle);
      color: var(--marketplaceColor);
      border-color: var(--marketplaceColor);
      & > svg {
        transition: var(--transitionStyle);
        & path {
          transition: var(--transitionStyle);
          stroke: var(--marketplaceColor);
        }
      }
    }
    & > svg {
      width: 18px;
      height: 18px;
      margin-right: 10px;
      transition: var(--transitionStyle);
      & path {
        transition: var(--transitionStyle);
        stroke: var(--colorGrey600);
      }
    }
  }
}

.shareProductSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 32px;
  flex-wrap: wrap;
  @media (--viewportSmall) {
    flex-wrap: nowrap;
  }
  & > button {
    border-radius: 100px;
    box-shadow: var(--boxShadow);
    padding: 10px 20px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: solid 1px var(--colorGrey100);
    font-size: 14px;
    line-height: 110%;
    width: 100%;
    cursor: pointer;
    transition: var(--transitionStyle);
    &:hover {
      transition: var(--transitionStyle);
      color: var(--marketplaceColor);
      border-color: var(--marketplaceColor);
      & > svg {
        transition: var(--transitionStyle);
        & path {
          transition: var(--transitionStyle);
          stroke: var(--marketplaceColor);
        }
      }
    }
    & > svg {
      width: 18px;
      height: 18px;
      margin-right: 10px;
      transition: var(--transitionStyle);
      & path {
        transition: var(--transitionStyle);
        stroke: var(--colorGrey600);
      }
    }
  }
}
.reviewContent {
  composes: h4 from global;
  font-style: italic;
  white-space: pre-line;
  margin: 8px 0 0 0;

  @media (--viewportMedium) {
    max-width: 500px;
    margin: 7px 0 0 0;
  }
}

.reviewStars {
  display: block;
  margin-top: 4px;

  @media (--viewportMedium) {
    margin: 5px 0;
  }
}

.reviewStar {
  width: 12px;
  height: 12px;
  margin-right: 2px;
  font-styles: initial;
}
