@import '../../styles/customMediaQueries.css';
.reactSelect {
  & input {
    min-height: auto;
  }
}
.loader {
  & > svg {
    width: 16px;
    height: 16px;
    margin-left: 5px;
    margin-top: -2px;
  }
}
