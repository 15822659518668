@import '../../styles/customMediaQueries.css';

.root {
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
  & .listingPageWrapper {
    padding: 0 24px;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--colorFail);
  margin: 24px;
}
.sectionImages {
}

.sectionHero {
  position: relative;
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    overflow-y: hidden;
  }
}

.imageWrapperForSectionHero {
  composes: heroHeight;
  background-color: var(--colorGrey100); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  color: var(--colorGrey100);
  background-color: var(--colorGrey700);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.actionBarForProductLayout {
  margin: 24px;
  width: calc(100% - 48px);

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    width: 100%;
  }

  @media (--viewportLarge) {
    margin: 0 0 24px 0;
  }
}

.actionBarContainerForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.actionBarForHeroLayout {
  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.ownListingText {
  composes: h5 from global;
  margin: 14px 24px 11px 24px;

  @media (--viewportMedium) {
    margin: 24px 12px 24px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.addPayoutDetails,
.editListingLink {
  composes: h5 from global;
  flex-shrink: 0;
  margin: 14px 24px 11px 24px;
  color: var(--colorGrey100);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 24px 24px 24px 12px;
  }
}

.missingPayoutDetailsText {
  color: var(--colorAttention);
}

.payoutDetailsWarning {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--colorWhite);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: calc(var(--contentMaxWidth) + 72px);
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: var(--contentMaxWidth);
    padding: 0;
  }
}

.mainColumnForProductLayout {
  width: 100%;
  position: relative;
  @media (--viewportLarge) {
    max-width: 55%;
    width: 100%;
    position: sticky;
    top: 100px;
    height: fit-content;
  }
  & :global(.slick-slider) {
    position: relative;
  }
  & :global(.slick-dots) {
    position: relative;
    bottom: 0;
    max-width: 80%;
    margin: 0 auto;
    overflow: hidden;
    display: flex !important;

    @media (--viewportSmall) {
      align-items: center;
      justify-content: center;
    }
  }
  & :global(.slick-dots > li) {
    width: 50px;
    min-width: 50px;
    min-height: 50px;
    height: 50px;
    @media (--viewportMedium) {
      width: 64px;
      min-width: 64px;
      min-height: 64px;
      height: 64px;
    }
  }
  & :global(.slick-dots > li > div) {
    border-radius: 6px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border: solid 1px var(--colorWhite);
    @media (--viewportMedium) {
      border-radius: 10px;
      border: solid 2px var(--colorWhite);
    }
  }
  & :global(.slick-dots > li.slick-active > div) {
    border: solid 1px var(--marketplaceColor);
    @media (--viewportMedium) {
      border: solid 2px var(--marketplaceColor);
    }
  }
  & :global(.slick-dots > li > div div) {
    height: 100%;
  }
  & :global(.slick-dots > li > div div picture) {
    height: 100%;
    display: flex;
  }
  & :global(.slick-arrow) {
    width: 30px;
    height: 30px;
    z-index: 1;
    top: auto;
    bottom: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @media (--viewportMedium) {
      width: 40px;
      height: 40px;
    }
  }
  & :global(.slick-arrow > svg) {
    border: solid 1px rgba(232, 232, 232, 1);
    border-radius: 100px;
    transition: var(--transitionStyle);
    width: 100%;
    height: 100%;
  }
  & :global(.slick-arrow:hover > svg) {
    transition: var(--transitionStyle);
    border-color: var(--marketplaceColor);
  }
  & :global(.slick-arrow:hover > svg > path) {
    stroke: var(--marketplaceColor);
    transition: var(--transitionStyle);
  }
  & :global(.slick-arrow::before) {
    content: '';
  }
  & :global(.slick-prev) {
    left: 0;
  }
  & :global(.slick-next) {
    right: 0;
  }
}

.orderColumnForProductLayout {
  width: 100%;
  margin-top: 20px;

  @media (--viewportLarge) {
    display: block;
    padding-left: 48px;
    margin-top: 0px;
    max-width: calc(100% - 55%);
  }
  & .breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 12px;
    & > * {
      font-size: 14px;
      line-height: 100%;
      color: var(--colorGrey600);
    }
    & > a {
      color: var(--colorGrey900);
      transition: var(--transitionStyle);
      &:hover {
        color: var(--marketplaceColor);
        transition: var(--transitionStyle);
      }
    }
    & > span {
      color: var(--colorGrey600);
    }
    & > svg {
      fill: none;
      width: 24px;
    }
  }
}

.contentWrapperForProductLayout {
  padding: 16px 0 0 0;
  margin: 0px 0 56px 0;
  width: 100%;

  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidthPages);
    margin: 0px auto 56px auto;
  }

  @media (--viewportLarge) {
    display: flex;
    flex-direction: column;
    margin: 0px auto 117px auto;
  }
}

.mainContentWrapper {
  flex-direction: column;

  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
  }
}

.gallarySection {
  &.gallarySlider {
    & :global(.slick-dots.slick-thumb) {
      & img {
        border-style: none;
        max-width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    & :global(.slick-list) {
      border-radius: 8px;
      margin-bottom: 10px;
      border: solid 1px rgba(232, 232, 232, 1);
    }
    & .sliderItem {
      height: 100%;
      width: 100%;
      & > div {
        position: relative;
        padding-bottom: 100%;
        height: 100%;
        width: 100%;
        & > img {
          max-width: 100%;
          width: 100%;
          height: 100%;
          max-height: 100%;
          object-fit: contain;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
.moreItemSeller {
  margin: 30px 0;
  float: left;
  width: 100%;
  @media (--viewportMedium) {
    margin: 48px 0;
  }
  & :global(.slick-slider) {
    width: 100%;
  }
  & :global(.slick-list) {
    margin: 0 -10px;

    @media (min-width: 1300px) {
      margin: 0 -15px;
    }
    & :global(.slick-track) {
      & :global(.slick-slide) {
        :global(.slick-slider) {
          width: 100%;
          & :global(.slick-list) {
            margin: 0px;
            & :global(.slick-slide) {
              padding: 0px;
            }
          }
        }
      }
    }
  }
  & :global(.slick-track) {
  }
  & :global(.slick-slide) {
    padding: 10px 10px;

    @media (min-width: 1300px) {
      padding: 15px 15px;
    }

    & img {
      max-width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  & :global(.slick-dots) {
    bottom: -40px;
    & > li {
      margin: 0;
      & > button {
        padding: 0;
        &::before {
          font-size: 8px;
        }
      }
    }
  }
  & :global(.slick-arrow) {
    width: 40px;
    height: 40px;
    display: inline-flex !important;
    background-color: var(--colorWhite);
    align-items: center;
    justify-content: center;
    box-shadow: var(--boxShadowPopupLight);
    border-radius: 100px;
    z-index: 1;
    &:hover,
    &:focus {
      background-color: var(--marketplaceColor);
    }
  }
  & :global(.slick-arrow.slick-prev) {
    left: 16px;
  }
  & :global(.slick-arrow.slick-next) {
    right: 16px;
  }
  & :global(.slick-arrow.slick-prev.slick-disabled) {
    background-color: var(--colorGrey100);
    opacity: 0;
  }
  & :global(.slick-arrow.slick-next.slick-disabled) {
    background-color: var(--colorGrey100);
    opacity: 0;
  }

  & :global(.slick-arrow.slick-prev::before) {
    content: ' ';
    display: inline-block;
    width: 10px;
    height: 16px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDkgMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03LjQ5OTkgMTUuMjI1QzcuMzQxNTcgMTUuMjI1IDcuMTgzMjQgMTUuMTY2NyA3LjA1ODI0IDE1LjA0MTdMMS42MjQ5IDkuNjA4MzVDMC43NDE1NjkgOC43MjUwMiAwLjc0MTU2OSA3LjI3NTAxIDEuNjI0OSA2LjM5MTY4TDcuMDU4MjQgMC45NTgzNUM3LjI5OTkgMC43MTY2ODMgNy42OTk5IDAuNzE2NjgzIDcuOTQxNTcgMC45NTgzNUM4LjE4MzI0IDEuMjAwMDIgOC4xODMyNCAxLjYwMDAyIDcuOTQxNTcgMS44NDE2OEwyLjUwODI0IDcuMjc1MDJDMi4xMDgyNCA3LjY3NTAyIDIuMTA4MjQgOC4zMjUwMiAyLjUwODI0IDguNzI1MDJMNy45NDE1NyAxNC4xNTgzQzguMTgzMjQgMTQuNCA4LjE4MzI0IDE0LjggNy45NDE1NyAxNS4wNDE3QzcuODE2NTcgMTUuMTU4MyA3LjY1ODI0IDE1LjIyNSA3LjQ5OTkgMTUuMjI1WiIgZmlsbD0iIzFBMUUyNSIvPgo8L3N2Zz4K);
  }
  & :global(.slick-arrow.slick-next::before) {
    content: ' ';
    display: inline-block;
    width: 10px;
    height: 16px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDkgMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjUwMDEgMTUuMjI1QzEuNjU4NDMgMTUuMjI1IDEuODE2NzYgMTUuMTY2NyAxLjk0MTc2IDE1LjA0MTdMNy4zNzUxIDkuNjA4MzVDOC4yNTg0MyA4LjcyNTAyIDguMjU4NDMgNy4yNzUwMSA3LjM3NTEgNi4zOTE2OEwxLjk0MTc2IDAuOTU4MzVDMS43MDAxIDAuNzE2NjgzIDEuMzAwMSAwLjcxNjY4MyAxLjA1ODQzIDAuOTU4MzVDMC44MTY3NjMgMS4yMDAwMiAwLjgxNjc2MyAxLjYwMDAyIDEuMDU4NDMgMS44NDE2OEw2LjQ5MTc2IDcuMjc1MDJDNi44OTE3NiA3LjY3NTAyIDYuODkxNzYgOC4zMjUwMiA2LjQ5MTc2IDguNzI1MDJMMS4wNTg0MyAxNC4xNTgzQzAuODE2NzYzIDE0LjQgMC44MTY3NjMgMTQuOCAxLjA1ODQzIDE1LjA0MTdDMS4xODM0MyAxNS4xNTgzIDEuMzQxNzYgMTUuMjI1IDEuNTAwMSAxNS4yMjVaIiBmaWxsPSIjMUExRTI1Ii8+Cjwvc3ZnPgo=);
  }
}
.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 50px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {
  }

  @media (--viewportLarge) {
    display: block;
    margin-top: 79px;
    margin-left: 60px;
    border-left: 1px solid var(--colorGrey100);
    padding-left: 60px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.orderPanel {
  @media (--viewportLarge) {
    position: sticky;
    top: 108px;
  }
}

.productOrderPanel {
  margin-bottom: 14px;
  float: left;
  width: 100%;
  @media (--viewportLarge) {
    display: block;
  }
}

.orderPanelTitle {
  margin: 0 0 10px 0;
  font-size: 26px;
  line-height: 120%;
  color: var(--colorGrey900);
  text-transform: capitalize;
  @media (--viewportMedium) {
    font-size: 26px;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flexbox would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--colorGrey700);
  }
}

.authorNameLink {
  composes: textSmall from global;
  color: var(--colorGrey700);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionText {
  padding: 0;
  margin-bottom: 24px;
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */
  composes: h4 from global;
  padding: 0;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey900);

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.sectionHeadingWithExtraMargin {
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.text {
  font-weight: var(--fontWeightRegular);
  padding: 1px 0 5px 0;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
    margin: 0;
  }
}

.ingress {
  font-size: 16px;
  line-height: 24px;
  color: rgba(88, 87, 87, 1);
  margin: 0;
  white-space: normal;
  word-wrap: break-word;
  font-weight: var(--fontWeightRegular);
}

.sectionDetails,
.sectionMultiEnum {
  padding: 0;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    margin-bottom: 40px;
  }
}
.sectionDetails {
  padding: 0px;
  float: left;
  width: 100%;
  margin-bottom: 24px;
  cursor: pointer;
  & .details {
    margin: 0;
    /* border: solid 1px rgba(232, 232, 232, 1); */
    border-radius: 10px;

    & .detailsRow {
      display: inline-flex;
      width: 100%;
      padding: 10px 0px;
      line-height: 36px;
      list-style: none;
      font-weight: var(--fontWeightRegular);
      border-bottom: solid 1px rgba(232, 232, 232, 1);
      &:last-child {
        border-bottom: none;
      }
      & > span {
        width: 50%;
        font-size: 16px;
        line-height: 24px;
        color: var(--colorGrey900);
        font-weight: var(--fontWeightMedium);

        @media (--viewportLarge) {
          width: 50%;
        }

        &.detailLabel {
          width: 50%;
          color: var(--colorGrey600);
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.09px;
          font-weight: var(--fontWeightRegular);

          @media (--viewportLarge) {
            width: 50%;
          }
        }
      }
    }
  }
}

.sectionMap {
  padding: 0;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.sectionReviews,
.sectionAuthor {
  padding: 0px;
  margin-top: 42px;
  scroll-margin-top: calc(var(--topbarHeight) + 24px);

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 40px;
    scroll-margin-top: calc(var(--topbarHeightDesktop) + 24px);
  }
  @media (--viewportLarge) {
    padding: 8px 0;
  }
}

.details {
  padding-bottom: 1px;
  margin: 0;
}

.detailsRow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0 4px 0;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular);

  &:not(:last-child) {
    border-bottom: 1px solid var(--colorGrey100);
  }

  @media (--viewportMedium) {
    padding: 5px 0 2px 0;
    line-height: 32px;
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
}

.inquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.inquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
    margin-bottom: 7px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.productGallery {
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 24px 0 48px 0;
  }
  @media (--viewportLarge) {
    margin: 0 0 40px 0;
    padding-bottom: 4px;
  }
}

.mobileHeading {
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.productDesktopHeading {
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {
}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}
