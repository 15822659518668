@import '../../styles/customMediaQueries.css';
/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__item + .accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
  padding: 10px 0;
  color: #444;
  cursor: pointer !important;
  width: 100%;
  text-align: left;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion__button:hover {
}
.accordion__icon {
  display: inline-block;
  height: 10px;
  width: 10px;
  min-width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true'] .accordion__icon,
.accordion__button[aria-selected='true'] .accordion__icon {
  transform: rotate(45deg);
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 0px 0 20px;
  animation: fadein 0.35s ease-in;
}
.accordion__panel p {
  margin: 0;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 140%;
}
/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
