@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  border-radius: var(--borderRadiusLarge);
  background-color: var(--colorWhite);

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100);
  /* Loading BG color */
  border-radius: var(--borderRadiusLarge);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }

  & > div {
    padding-bottom: 100% !important;
  }
}
.cartIcon {
  transition: var(--transitionStyle);
  background-color: var(--colorWhite);
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 100px;
  top: 10px;
  right: 10px;
  z-index: 1;
  cursor: pointer;
  box-shadow: var(--boxShadowPopupLight);

  & > svg {
    width: 20px;
    height: 20px;
    transition: var(--transitionStyle);

    & path {
      transition: var(--transitionStyle);
    }
  }

  &:hover {
    transition: var(--transitionStyle);

    & > svg {
      transition: var(--transitionStyle);

      & path {
        transition: var(--transitionStyle);
        /* stroke: var(--colorGrey700); */
      }
    }
  }
}

.rootForImage {
  border-radius: var(--borderRadiusLarge);
  width: 100%;
  height: 100%;
}

.info {
  display: flex;
  flex-direction: column;
  padding: 16px 10px;

  & p {
    font-size: 14px;
    line-height: 160%;
    font-weight: var(--fontWeightRegular);
    color: var(--colorGrey700);
    padding: 0;
    margin: 0;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
}

.priceValue {
  font-size: 18px;
  line-height: 100%;
  font-weight: var(--fontWeightBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 100%;
  color: var(--colorGrey400);
  display: inline-flex;
  align-items: center;
  margin-left: 6px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
}

.mainInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 8px 0;
}

.title {
  font-size: 16px;
  line-height: 110%;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey900);
  margin: 0;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.authorSec {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .authorInfo {
    font-size: 14px;
    font-weight: var(--fontWeightSemiBold);
    color: var(--colorGrey900);
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 0;

    & > a {
      margin-right: 10px;
      position: relative;
    }

    & > span {
      width: 68px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.listViewCard {
}

.cardImg {
  position: relative;
  padding-bottom: 100%;
  border-top-left-radius: var(--borderRadiusLarge);
  border-top-right-radius: var(--borderRadiusLarge);
  overflow: hidden;
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
