@import '../../styles/customMediaQueries.css';

/* Content is visible as modal layer */
/* Content is visible as modal layer */
.isOpen {
  width: 100%;
  z-index: 100;

  /* scrollLayer is the .root for the Modal */
  & .scrollLayer {
    flex-grow: 1;
    display: flex;

    @media (--viewportMedium) {
      justify-content: center;
      align-items: flex-start;
    }

    /* Add default background color to avoid bouncing scroll showing the
   page contents from behind the modal. */

    /* Additional styles for the modal window, dimming the background and positioning the modal */
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    background-color: rgba(0, 0, 0, 0.4);
    min-height: 100vh;

    @media (max-width: 767px) {
      display: flex;
      align-items: flex-end;
      min-height: auto;
    }

    @media (--viewportMedium) {
      align-items: center;
      justify-content: center;
      padding: 0;
      background-image: none;
    }
  }

  & .container {
    flex-grow: 1;

    /* Create context so the close button can be positioned within it */
    position: relative;

    /* Display and dimensions */
    display: flex;
    flex-direction: column;
    background-color: var(--colorWhite);
    border-radius: var(--borderRadiusLarge);
    border-bottom: none;
    padding: 0;
    margin: 0;
    min-height: 100vh;
    height: 100%;
    transform: translateZ(0);
    overflow: hidden;

    @media (--viewportMedium) {
      flex-basis: 480px;
      flex-grow: 0;
      margin-top: 12.5vh;
      margin-bottom: 12.5vh;
    }

    @media (max-width: 767px) {
      height: 90%;
      min-height: auto;
      border-top-left-radius: var(--borderRadiusLarge);
      border-top-right-radius: var(--borderRadiusLarge);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    @media (--viewportMedium) {
      flex-basis: 90%;
      min-height: auto;
      height: auto;
    }

    @media (--viewportLarge) {
      flex-basis: 780px;
    }
  }
  & .outerWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  padding: 14px 24px;
  border-bottom: solid 1px var(--colorGrey100);
  background-color: var(--colorWhite);
  z-index: calc(var(--zIndexModal) + 1);

  & .close {
    position: relative;
    z-index: 0;
    padding: 10px;

    & > svg {
      margin: 0;
    }
  }
}
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
  margin: 0;
  color: var(--colorGrey900);
}

.fullWidthModal {
  border: solid red;
  background-color: var(--colorWhite);

  & .scrollLayer {
    background-color: var(--colorWhite);
  }
}

.mobileMenuOpen {
  & .container {
    flex-basis: 100%;
    margin-top: 0;
  }
}

.isOpenInPlace {
  composes: isOpen;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.isOpenInPortal {
  composes: isOpen;
  display: block;
  /* height: calc(var(--vh, 1vh) * 100); */
  position: absolute;
  top: 0;
  left: 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.content {
  padding: 24px;
  max-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 80px 24px 100px;

  @media (max-width: 767px) {
    padding: 80px 24px 100px;
  }

  @media (--viewportMedium) {
    max-height: 70vh;
  }
}

/* Content is explicitly hidden (this default can be overridden with passed-in class) */
/* The use case for having both .isOpen and .isClosed is ModalInMobile use case       */
/* where desktop layout should not get any styling from Modal component.              */
.isClosed {
  display: none;
}

.close {
  composes: marketplaceModalCloseStyles from global;
  padding: 24px 30px;
  left: 0;
}

.closeText {
  composes: marketplaceModalCloseText from global;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
}

.closeLight {
  color: var(--colorGrey600);
  transition: var(--transitionStyleButton);

  &:enabled:hover,
  &:enabled:active {
    color: var(--colorWhite);
  }
}

.focusedDiv {
  &:focus {
    outline: none;
  }
}
