@import '../../styles/customMediaQueries.css';

.root {
  composes: h4 from global;
  margin-top: 6px;
  margin-bottom: 0;
  color: var(--colorFail);
  font-weight: var(--fontWeightRegular);
  overflow: hidden;
  font-size: 14px;
  line-height: 100%;

  @media (--viewportMedium) {
    margin-top: 8px;
  }
}
